import request from '@/util/request';
const competition = '/api/qt-competition/'
const competition1 = '/api/competition/'
// 赛事列表
export function getCompetitionList(params) {
  return request({
    url: `${competition}competition/front/page`,
    method: 'get',
    params
  })
}

// 赛事详情 {competitionId}
export function getCompetitionItemDetails(id) {
  return request({
    url: `${competition}competition/front/info/${id}`,
    method: 'get'
  })
}

// 参赛人员列表
export function getPersonnel(params) {
  return request({
    url: `${competition}competition/personnel/list`,
    method: 'get',
    params
  })
}

// 添加/修改参赛人员
export function editPersonnel(data) {
  return request({
    url: `${competition}competition/personnel/edit`,
    method: 'post',
    data
  })
}

// 删除参赛人员
export function delPersonnel(id) {
  return request({
    url: `${competition}competition/personnel/del/${id}`,
    method: 'post',
  })
}
//导入参赛人员名单
export function importPersonnel(data, type) {
  return request({
    url: `${competition}competition/excel/import?type=${type}`,
    method: 'post',
    data
  })
}
//体育赛事 -- 赛事详情:添加人员 ：手机号码获取用户信息{phone}
export function getUserInfoByPhone(params) {
  return request({
    url: "/api/system/Permission/Users/getUserInfoByPhone",
    method: 'get',
    params
  })
}

//体育赛事 -- 确定报名 : 下一步
export function toApply(data) {
  return request({
    url: "/api/competitionUniversal/apply/toApply",
    method: 'POST',
    data
  })
}


//体育赛事 -- 获取确认报名信息
export function confirmApplyInfo(data, applyIds, itemId) {
  return request({
    url: `/api/competitionUniversal/app/confirmApplyInfo?applyIds=${applyIds}&itemId=${itemId}`,
    method: 'POST',
    data
  })
}

//体育赛事 -- 确认报名信息:赛事-批量创建订单
export function createOrder(data) {
  return request({
    url: `/api/competitionUniversal/apply/createOrder`,
    method: 'POST',
    data
  })
}

//确认报名
export function enrollConfirm(data) {
  return request({
    url: `${competition}competition/sign/personnel/confirm/sign`,
    method: 'POST',
    data
  })
}
//赛事创建订单
export function competitionPersonnelApplyOrder(data) {
  return request({
    url: `${competition}competition/sign/personnel/applyOrder`,
    method: 'POST',
    data
  })
}

//体育赛事 -- 我参与的赛事{	competitionName:""}
export function getMyJoinCompetition(data) {
  return request({
    url: `/api/competitionUniversal/app/getMyJoinCompetition`,
    method: 'POST',
    data
  })
}

//体育赛事 -- 当前用户已舔过的用户信息
export function getCurrUserApplyUser(params) {
  return request({
    url: `/api/competitionUniversal/app/getCurrUserApplyUser`,
    method: 'get',
    params
  })
}

//体育赛事 -- 获取赛事小项目列表
export function queryApplyCompetition(params) {
  return request({
    url: `/api/competitionUniversal/app/queryApplyCompetition`,
    method: 'get',
    params
  })
}

// 体育赛事 -- 详情按钮状态：我的确认报名按钮控制
export function myJoinCompetitionTow(params) {
  return request({
    url: `/api/competitionUniversal/app/myJoinCompetitionTow`,
    method: 'get',
    params
  })
}

// 体育赛事 -- 详情:确认报名信息(拒绝或同意) itemId ， status：2 同意，3 拒绝
export function myJoinTrue(params) {
  return request({
    url: `/api/competitionUniversal/app/updateConfirm/byItemId`,
    method: 'get',
    params
  })
}

// 体育赛事 -- 报名，添加人员信息
export function isApplyCompetition(params) {
  return request({
    url: `/api/competitionUniversal/apply/isApplyCompetition`,
    method: 'get',
    params
  })
}

// 体育赛事 -- 我的报名信息
export function myApplyInfo(params) {
  return request({
    url: `/api/competitionUniversal/app/myApplyInfo`,
    method: 'get',
    params
  })
}

// 体育赛事 -- 我的赛事订单信息
export function endRegistrationProcess(params) {
  return request({
    url: `/api/competitionUniversal/app/endRegistrationProcess`,
    method: 'get',
    params
  })
}

// 体育赛事 -- 检查用户是否有权限报名
// {
//   appUserId
//   competitionId
//   identityCode
//   minIdList:[]?appUserId=${appUserId}&competitionId=${competitionId}&identityCode=${identityCode}
// }
export function personCheck(data) {
  return request({
    url: `/api/competitionUniversal/apply/personCheck`,
    method: 'post',
    data
  })
}
//富文本回显
export function getTextarea(data) {
  return request({
    url: '/api/file/getUploaderData',
    method: 'POST',
    data: data
  })
}
//赛事报名人员校验
export function competitionVerify(data) {
  return request({
    url: `${competition}checkConfig/verify`,
    method: 'post',
    data
  })
}
//用户参与列表
export function competitionSignPersonnelUserApplyList(data) {
  return request({
    url: `${competition}competition/sign/personnel/user/apply/list?name=${data.name}&size=${data.size}&current=${data.current}`,
    method: 'get',
    data
  })
}
//获取报名信息
export function competitionSignPersonnelGetApplyPersonList(data) {
  return request({
    url: `${competition}/competition/sign/personnel/getApplyPersonList?name=${data.name}&size=${data.size}&current=${data.current}&competitionId=${data.competitionId}&orderNo=${data.orderNo}`,
    method: 'get',
    data
  })
}
